import React from "react"
import {Spinner, Col, Container, Row} from 'reactstrap'

import Layout from '../components/Layout'
import MovieCardLargeList from "../components/MovieCardLargeList"
import { getWatchlistFromLocalstorage } from "../utils/localStorage"
import { getWatchlist, syncWatchlist } from "../apis/watchlist"
import Observer from '@researchgate/react-intersection-observer'
import { getToken } from "../api"
import { Trans } from "react-i18next"
import { graphql } from "gatsby"


// const ExploreSidebar = React.lazy(() => import("../components/ExploreSidebar"));
// const ExploreBody = React.lazy(() => import("../components/ExploreBody"));


class WatchList extends React.Component {

  state = {
    watchlist: [],
    
    nextPage: null,
    isLoading: false,
    pageSize: 40,
  }

  componentWillMount() {
    if (typeof window !== "undefined") {
      require("intersection-observer")
    }
  }

  async componentDidMount(){
    const {pageSize} = this.state
    let watchlist = await getWatchlistFromLocalstorage()
    this.setState({watchlist: watchlist.slice(0,pageSize) })
    const token = await getToken()
    
    if (!token) return

    const liveWatchlist = await getWatchlist()
    if (liveWatchlist && liveWatchlist.results){
      let resultLength = liveWatchlist.results.length


      if (liveWatchlist.next){
        this.setState({nextPage: 2})
      }
      

      if (watchlist.length && watchlist.length > resultLength && watchlist.length < pageSize){
        alert(watchlist.length)
        alert(resultLength)
        // If local watchlist more up to date
        await syncWatchlist()
        return
      } else {
        // If remote watchlist more up to date
        localStorage.setItem('watchlist', JSON.stringify(liveWatchlist.results))
        this.setState({watchlist: liveWatchlist.results})
        return
      }
    }
  }

  handleNextPageLoad = async (event, isIntersecting) => {
    if (isIntersecting && this.state.nextPage && !this.state.isLoading && event.intersectionRatio === 1) {
      this.setState({isLoading: true})
      await this.fetchNextPage()
    }
  }

  fetchNextPage = async () => {
    const {nextPage} = this.state
    try {
      const result = await getWatchlist(nextPage)
      const watchlist = result.results
      const newWatchlist = [...this.state.watchlist, ...watchlist]
      this.setState({
        nextPage: result.next,
        watchlist: newWatchlist,
        isLoading: false,
      })
      localStorage.setItem('watchlist', JSON.stringify(newWatchlist))
    } catch(err){
      console.log(err)
    }
 
  }


  render(){
    const {watchlist} = this.state

    return (
      <Layout>
      <Container>
        <div className=" bg- pr-4 py-3 mb-3 mt-3">
            <h1 className="font-weight-bold display-4 text-dark"><Trans>Ma liste</Trans></h1>
            {/* <h1 className="font-weight-bold text-dark text-center"> Ma liste </h1> */}
        </div>
        <Row className="mt-3">
            <Col md="8">
              { !watchlist.length 
                ? <p className="text-muted"><Trans>Pas encore de films dans votre liste</Trans></p>
                : <MovieCardLargeList movies={watchlist} watchlist={watchlist}/>
              }
                
                <Observer onChange={this.handleNextPageLoad} rootMargin="0% 0% 25%">
                  <div className="text-center">{this.state.isLoading && <Spinner className="m-auto" size="sm"/>}</div>
                </Observer>
            </Col>
            <Col md="4">
                <div className="bg-white px-4 py-3 mb-3">
                    <h6 className="font-weight-bold text-dark"><Trans>Pour vous</Trans></h6>
                    {/* Machine learning basé sur votre liste */}
                    <ul className="list-unstyled">

                    </ul>
                </div>
            </Col>
        </Row>
      </Container>
    </Layout>
    )
  }
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


export default WatchList
